import React from 'react';
import NoAccess from './components/NoAccess';

function App() {
  return (
    <div>
			<header className="App-header">
        <img src="https://semanadafotografia.com.br/2020/assets/img/logo.png" alt="Semana da Fotografia" />
      </header>
      
      <NoAccess>Acesse o link enviado para o seu e-mail para acessar as palestras 😉</NoAccess>
    </div>
  );
}

export default App;
