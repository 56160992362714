import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios'
import NoAccess from './components/NoAccess'
import DaysList from './components/DaysList'
import CoursesList from './components/CoursesList'
import VideoContent from './components/VideoContent'
import VoteContext from './components/VoteContext'
import { ParamTypes, StateType, ServerError, SuccessOrError, ServerSuccess, VoteOrError, VoteSuccess } from './../interfaces'

function App() {
  const adWatched = localStorage.getItem('@sdf-2020/ad-watched');
  const { key } = useParams<ParamTypes>();
  const [state, setState] = useState<StateType>({
    loading: true,
    texts: {
      title: 'Seja bem-vindo a Semana da Fotografia 2020',
      title2: 'Agora, selecione a palestra que deseja assistir',
      description: 'Selecione o dia desejado abaixo',
      afterEnd: 'Gostou da palestra de',
      nextOne: 'Próxima palestra já disponível!',
      continueButton: 'Continuar assistindo',
      todayFinish: 'Por hoje é só. Nos vemos amanhã',
      finished: 'As palestras da Semana da Fotografia acabaram mas você continua tendo acesso a todas as palestras.',
      thankFeedback: 'Agradecemos seu feedback.'
    },
    name: '',
    data: [],
    page: 'list',
    voteContext: {
      loading: false,
      status: 'unvoted'
    }
  })

  const getErrorMessage = useCallback((errorCode: string) => {
    if(!errorCode) return undefined; 
    
    switch(errorCode) {
      case 'server-error':
        return 'Nosso servidor não conseguiu atingir seu destino, atualize ou tente novamente mais tarde.' 
      case 'no-auth':
        return 'Não achamos seu cadastro, verifique seu e-mail com o link correto para acessar as palestras. Qualquer coisa estamos no Whatsapp (47) 99692-7069' 
      case 'other-pc':
        return 'Você acessou as palestras por outro dispositivo. Você deve continuar acessando por ele para ver as palestras ou peça para nossa equipe resetar seu acesso pelo Whatsapp (47) 99692-7069' 
      default:
        return undefined;
    }
  }, [])

  const getAccessByKey = useCallback(async (key: string) => {
    setState(p => ({
      ...p,
      loading: true,
      error: undefined
    }))
    try {
      const response: { data: SuccessOrError} = await axios.get(`https://events.iphotoeditora.com.br/api/credential-online-event/semanadafotografia2020/${key}`)
      if((response.data as ServerError).error) {
        setState(p => ({
          ...p,
          loading: false,
          error: (response.data as ServerError).error
        }))
      } else {
        setState(p => ({
          ...p,
          loading: false,
          name: (response.data as ServerSuccess).name,
          data: (response.data as ServerSuccess).days,
          texts: (response.data as ServerSuccess).texts
        }))
      }
     
    } catch {
      setState(p => ({
        ...p,
        loading: true,
        error: 'server-error'
      }))
    }
  }, [])

  useEffect(() => {
    if(key) getAccessByKey(key)
  }, [getAccessByKey, key])

  useEffect(() => {
    setState(p => ({...p, voteContext: { ...p.voteContext, status: 'unvoted' }}))
  }, [state.selectedCourse])

  const DaysListAction = useCallback((indexOfDay: number) => {
    const day = state.data.find((f, i) => i === indexOfDay)
    if(day && day.enabled) {
      setState(p => ({
        ...p,
        page: 'day',
        selectedDay: {
          data: day,
          index: indexOfDay
        },
      }))
    }
  }, [state.data])

  const CoursesListAction = useCallback((indexOfCourse: number) => {

    const course = state.data[state.selectedDay!.index].courses.find((f, i) => i === indexOfCourse)
    if(course) {
      setState(p => ({
        ...p,
        page: 'video',
        selectedCourse: {
          data: course,
          index: indexOfCourse
        },
      }))
    }
  }, [state.data, state.selectedDay])

  const backToList = useCallback(() => {
    setState(p => ({
      ...p,
      page: 'list',
    }))
  }, [])

  const nextAction = useCallback(() => {
    setState(p => ({
      ...p,
      selectedDay: { 
        index: state.nextCourse!.day,
        data: state.data[state.nextCourse!.day],
      },
      selectedCourse: {
        index: state.nextCourse!.index,
        data: state.data[state.nextCourse!.day].courses[state.nextCourse!.index]
      },
      page: 'video',
    }))
  }, [state.data, state.nextCourse])

  const backToCoursesList = useCallback(() => {
    setState(p => ({
      ...p,
      page: 'day',
    }))
  }, [])

  
  const voteAction = useCallback(async (voteIndex) => {
    setState(p => ({
      ...p,
      voteContext: {
        ...p.voteContext,
        loading: true
      }
    }))
    try {
      const response: { data: VoteOrError} = await axios.post(`https://events.iphotoeditora.com.br/api/credential-online-event-vote/semanadafotografia2020`, {
        accesskey: key,
        item: state.selectedCourse!.data.identifier,
        vote: voteIndex
      })
      if((response.data as ServerError).error) {
        alert('Erro genérico ao votar. Tente novamente mais tarde.')
        setState(p => ({
          ...p,
          voteContext: {
            status: 'unvoted',
            loading: false
          }
        }))
      } else {
        console.log('VOTO COMPUTADO', (response.data as VoteSuccess).status)
        setState(p => ({
          ...p,
          voteContext: {
            loading: false,
            status: (response.data as VoteSuccess).status
          }
        }))
      }
     
    } catch {
      alert('Erro genérico ao votar. Tente novamente mais tarde.')
      setState(p => ({
        ...p,
        voteContext: {
          status: 'unvoted',
          loading: false
        }
      }))
    }
  }, [key, state.selectedCourse])
  const videoEndAction = useCallback(() => {
    let nextVideo = { day: 0, video: 0 };
    let message = 'nextOne';
    const try1 = state.data[state.selectedDay!.index].courses.findIndex((f, i) => i === state.selectedCourse!.index+1)
    if(try1 !== -1) {
      nextVideo = { day: state.selectedDay!.index, video: try1 };
    } else if(try1 === -1 && state.data[state.selectedDay!.index+1]) {
      if(state.data[state.selectedDay!.index+1].enabled) {
        nextVideo = { day: state.selectedDay!.index+1, video: 0 }
      } else {
        message = 'todayEnd'
      }
    } else {
      message = 'totalEnd'
    }
    
    setState(p => ({
      ...p,
      page: 'vote',
      nextCourse: {
        message,
        index: nextVideo.video,
        day: nextVideo.day,
        data: p.data[nextVideo.day].courses[nextVideo.video]
      }
    }))
  }, [state.data, state.selectedCourse, state.selectedDay])

  return (
    <div>
			{(!state.loading && state.page !== 'video') && 
        <header className="App-header">
          <img className="App-logo" src="https://semanadafotografia.com.br/2020/assets/img/logo.png" alt="Semana da Fotografia" />
        </header>
      }
      
      {state.loading && <div className="lds-ring"><div></div><div></div><div></div><div></div></div>}
      {(!state.loading && !state.error && state.data.length > 0) && (
        <>
          {state.page === 'list' && <DaysList name={state.name} texts={state.texts} content={state.data} action={DaysListAction} />}
          {state.page === 'day' && <CoursesList texts={state.texts}  content={state.selectedDay?.data} action={CoursesListAction} backAction={backToList} />}
          {state.page === 'video' && <VideoContent ad={state.selectedCourse!.index === 1 && !adWatched ? true : false} content={state.selectedCourse!.data} backAction={backToCoursesList} endAction={videoEndAction} />}
          {state.page === 'vote' && <VoteContext texts={state.texts}  voteAction={voteAction} actual={state.selectedCourse!.data} backAction={backToCoursesList} endAction={videoEndAction} nextAction={nextAction} content={state.nextCourse!} voteContext={state.voteContext} />}

          <div className="footer">
            <h6>Parceiros</h6>
            <div className="footer-grid">
              <a href="http://www.redlab.com.br/" target="_blank" rel="noopener noreferrer"><img src="https://events.iphotoeditora.com.br/assets/uploads/redlab.png" className="img-fluid" alt="RedLab"/></a>

              <a href="https://www.epics.com.br/" target="_blank" rel="noopener noreferrer"><img src="https://events.iphotoeditora.com.br/assets/uploads/epics.png" className="img-fluid" alt="EPICS"/></a>

              <a href="https://lojaposclickalbum.com.br/home" target="_blank" rel="noopener noreferrer"><img src="https://events.iphotoeditora.com.br/assets/uploads/posclick.png" className="img-fluid" alt="PosClick"/></a>

              <a href="https://www.blackhold.com.br/" target="_blank" rel="noopener noreferrer"><img src="https://events.iphotoeditora.com.br/assets/uploads/blackhold.png" className="img-fluid" alt="Black Hold"/></a>

              <a href="https://iphotostore.com.br" target="_blank" rel="noopener noreferrer"><img src="https://events.iphotoeditora.com.br/assets/uploads/iphoto.png" className="img-fluid" alt="iPhoto"/></a>
            </div>
          </div>
        </>
      )}
      

      {(!state.loading && state.error) && (
        <NoAccess>{getErrorMessage(state.error)}</NoAccess>
      )}
      

    </div>
  );
}

export default App;
