import React, { ReactNode } from 'react';
import { Course, NextCourse, TextTypes, VoteState } from './../../interfaces'

interface PageProps {
	children?: ReactNode,
	content: NextCourse,
	backAction: Function,
	endAction: Function
	nextAction: Function,
	actual: Course,
	voteAction: Function,
	voteContext: VoteState
	texts: TextTypes
}

export default function ({content, actual, texts, voteContext, backAction, voteAction, nextAction, endAction}: PageProps) {
  return (
    <div className="App-course-watch">
			{/* <div className="App-course-watch-header">
				{/* <div className="App-course-watch-header-logo">
					<img className="App-logo" src="https://semanadafotografia.com.br/2020/assets/img/logo.png" alt="Semana da Fotografia" />
				</div> 
				{/* <div className="App-course-watch-details">
					<div><img src={content.image} alt={content.author} /></div>
					<div>
						<h2>{content.author}</h2>
						<p>{content.name}</p>
					</div>
					<div>
						<button className="back-action-button" onClick={() => backAction()}>Voltar</button>
					</div>
				</div> 
			</div> */}
			
			<div className="App-course-watch-video justify-center">
				<div className="nextPalestra">
					{!voteContext.loading && voteContext.status === 'unvoted' && (
						<>
							<h1>{texts.afterEnd} {actual.author}?</h1>
							<button onClick={() => voteAction(1)} className="emojiVote"><span role="img" aria-label="Não gostei">😥</span></button>
							<button onClick={() => voteAction(2)} className="emojiVote"><span role="img" aria-label="Mediano">😐</span></button>
							<button onClick={() => voteAction(3)} className="emojiVote"><span role="img" aria-label="Gostei">😃</span></button>
							<button onClick={() => voteAction(4)} className="emojiVote"><span role="img" aria-label="Amei">😍</span></button>
						</>
					)}
					{!voteContext.loading && voteContext.status === 'voted' && (
						<>
							<h1>{texts.thankFeedback}</h1>
						</>
					)}
					{!voteContext.loading && voteContext.status === 'alreadyVoted' && (
						<>
							<h1>Atualizamos seu feedback desta palestra <span role="img" aria-label="- não é possível votar 2 vezes.">😏</span></h1>
						</>
					)}
					{voteContext.loading && (
						<>
							<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
						</>
					)}
					
				</div>
				{content.message === 'nextOne' ? (
					<>
						<h2>{texts.nextOne}</h2>
						<div className="App-course-watch-details">
							<div><img src={content.data.image} alt={content.data.author} /></div>
							<div>
								<h2>{content.data.author}</h2>
								<p>{content.data.name}</p>
							</div>
							<div>
								<button className="back-action-button" onClick={() => nextAction()}>{texts.continueButton}</button>
							</div>
						</div>
					</>
				) : (content.message === 'totalEnd' ? (
					<div className="endMessage">{texts.finished}</div>
				) : (
					<div className="endMessage">{texts.todayFinish}</div>
				)) }
				
			</div>
			
		</div>
  );
}