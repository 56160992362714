import React, { ReactNode, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import YouTube from 'react-youtube';
import { Course } from './../../interfaces'

interface PageProps {
	children?: ReactNode,
	content: Course,
	backAction: Function,
	endAction: Function
	ad: boolean
}

export default function ({content, ad, backAction, endAction}: PageProps) {
	const [watchedAd, setWatchedAd] = useState(!ad)

  return (
    <div className="App-course-watch">
			<div className="App-course-watch-header">
				<div className="App-course-watch-header-logo">
					<img className="App-logo" src="https://semanadafotografia.com.br/2020/assets/img/logo.png" alt="Semana da Fotografia" />
				</div>
				{watchedAd && (
					<div className="App-course-watch-details">
						<div><img src={content.image} alt={content.author} /></div>
						<div>
							<h2>{content.author}</h2>
							<p>{content.name}</p>
						</div>
						<div>
							<button className="back-action-button" onClick={() => backAction()}>Voltar</button>
						</div>
					</div>
				)}
			
				{!watchedAd && (
					<div className="App-course-watch-details-ad">
						<h3>A palestra será exibida após um vídeo do nosso parceiro <a rel="noopener noreferrer" target="_blank" href="http://www.redlab.com.br">RedLab</a>.</h3>
					</div>
				)}
			</div>
			
			<div className="App-course-watch-video">
				{watchedAd && (
					<>
						{(content.id) ? (
							<Vimeo
								video={content.id}
								autoplay
								showTitle={false}	
								responsive={true}
								showByline={false}
								onEnd={() => endAction()}
							/>
						) : (
							<span>Ocorreu um erro ao buscar esta palestra <span role="img" aria-label=":(">😥</span></span>
						)}
					</>
				)}

				{!watchedAd && (
					<>
						
						<YouTube
							videoId="92wB366z8fs"
							containerClassName={"youtubeContainer"}
							opts={
								{
									playerVars: {
										autoplay: 1,
										color: 'white',
										disablekb: 1,
										modestbranding: 1,
										controls: 0,

									}
								}
							}	
							onEnd={() => {
								setWatchedAd(true)
								localStorage.setItem('@sdf-2020/ad-watched', 'true');
							}}
						/>
					</>
				)}

			</div>
			
		</div>
  );
}