import React, { ReactNode } from 'react';
import { Course, DayOfCourses } from './../../interfaces'
interface PageProps {
	children?: ReactNode,
	content: DayOfCourses,
	action: Function
}

export default function ({content, action}: PageProps) {
  return (
    <div className={`App-course-day ${!content.enabled && 'App-course-day-disabled'}`} onClick={() => action()} >
			<span>{content.day}</span>
			<div className="App-course-day-content">
				<ul>
					{content.courses.map((course: Course, i) => (
						<li key={`${Math.floor(Math.random() * 100)}${i}`}>
							<img src={course.image} alt={course.author} /> {course.author}
						</li>
					))}
				</ul>
			</div>
		</div>
  );
}