import React, { ReactNode } from 'react';
import { DayOfCourses, TextTypes } from './../../interfaces'
import DayOfCourse from './DayOfCourse';
interface PageProps {
	children?: ReactNode
	content: DayOfCourses[]
	action: Function
	texts: TextTypes
	name: string
}

export default function ({content,name, texts, action}: PageProps) {
  return (
		<div className="App-course-days">
			<h1 className="App-course-days-title">{name ? `${name}, `:''}{texts.title}</h1>
			<h4 className="App-course-days-subtitle">{texts.description}</h4>
			<div className="App-course-days-content">
				{content.map((dayContent: DayOfCourses, i: number) => (
					<DayOfCourse key={'daylist'+i} content={dayContent} action={() => action(i)} />
				))}
			</div>
		</div>
  );
}