import React, { ReactNode } from 'react';
import { Course, DayOfCourses, TextTypes } from './../../interfaces'
import CourseListItem from './CourseListItem';
import DayOfCourse from './DayOfCourse';
interface PageProps {
	children?: ReactNode
	content?: DayOfCourses
	action: Function
	backAction: Function
	texts: TextTypes
}

export default function ({content, texts, action, backAction}: PageProps) {
  return (
		<div className="App-course-days">
			{content ? (
				<>
					<h1 className="App-course-days-title">{texts.title2}</h1>
					<div className="App-course-days-content">
					{content.courses.map((courseContent: Course, i: number) => (
						<CourseListItem key={'courelist'+i} content={courseContent} action={() => action(i)} />
					))}
					</div>
					<button className="back-action-button" onClick={() => backAction()}>Voltar</button>
				</>
			) : (
				<h1 className="App-course-days-title">Não encontramos palestras para este dia :(</h1>
			)}
			
		</div>
  );
}