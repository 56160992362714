import React, { ReactNode } from 'react';
import { Course } from './../../interfaces'
interface PageProps {
	children?: ReactNode,
	content: Course,
	action: Function
}

export default function ({content, action}: PageProps) {
  return (
    <div className={`App-course-item ${!content.id && 'App-course-day-disabled'}`} onClick={() => content.id ? action() : () => {}} >
			<img src={content.image} alt={content.author} />
			<span>{content.author}</span>
			<p>{content.name}</p>
		</div>
  );
}