import React from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import Home from './pages/home'
import Access from './pages/video'

export default function Routes() {
  return (
		<Switch>
			<Route path="/assistir/:key">
				<Access />
			</Route>
			<Route path="/">
				<Home />
			</Route>
			
		</Switch>
  );
}
