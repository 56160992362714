import React, { ReactNode } from 'react';
import camera from './../../assets/camera.svg'

interface PageProps {
	children?: ReactNode,
	hideImage?: boolean
}

export default function (props: PageProps) {
  return (
    <div className="App-access">
			{!props.hideImage && (
				<img src={camera} alt="Camera" className="App-home-image" />
			)}
			<h1 className="App-home-title">
				{props.children ? props.children : (
					<>
						Para acessar o conteúdo da Semana da Fotografia verifique o <strong>e-mail cadastrado</strong> contendo um link para o seu acesso.
					</>
				)}
			</h1>
		</div>
  );
}